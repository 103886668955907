import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4760c933 = () => interopDefault(import('../pages/authorization/index.vue' /* webpackChunkName: "pages/authorization/index" */))
const _4684287e = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _9ab88bd4 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "" */))
const _26067bfc = () => interopDefault(import('../pages/partner/index.vue' /* webpackChunkName: "pages/partner/index" */))
const _7da889a3 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _52824056 = () => interopDefault(import('../pages/registration/index.vue' /* webpackChunkName: "pages/registration/index" */))
const _67b572b5 = () => interopDefault(import('../pages/request-password/index.vue' /* webpackChunkName: "pages/request-password/index" */))
const _72f5fa78 = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _6a53b776 = () => interopDefault(import('../pages/success-payment/index.vue' /* webpackChunkName: "pages/success-payment/index" */))
const _973c0ad2 = () => interopDefault(import('../pages/partner/activations-history/index.vue' /* webpackChunkName: "pages/partner/activations-history/index" */))
const _042c28f0 = () => interopDefault(import('../pages/partner/price-regulator/index.vue' /* webpackChunkName: "pages/partner/price-regulator/index" */))
const _22950149 = () => interopDefault(import('../pages/partner/sim-card/index.vue' /* webpackChunkName: "pages/partner/sim-card/index" */))
const _27eefe03 = () => interopDefault(import('../pages/partner/statistic/index.vue' /* webpackChunkName: "pages/partner/statistic/index" */))
const _f935eb8e = () => interopDefault(import('../pages/partner/withdrawal-funds/index.vue' /* webpackChunkName: "pages/partner/withdrawal-funds/index" */))
const _0ff440e8 = () => interopDefault(import('../pages/profile/history/index.vue' /* webpackChunkName: "pages/profile/history/index" */))
const _75aa77de = () => interopDefault(import('../pages/profile/loyalty-program/index.vue' /* webpackChunkName: "pages/profile/loyalty-program/index" */))
const _43f7087c = () => interopDefault(import('../pages/profile/pay/index.vue' /* webpackChunkName: "pages/profile/pay/index" */))
const _430c1f54 = () => interopDefault(import('../pages/profile/referral-program/index.vue' /* webpackChunkName: "pages/profile/referral-program/index" */))
const _429b59c6 = () => interopDefault(import('../pages/profile/sale-agreement/index.vue' /* webpackChunkName: "pages/profile/sale-agreement/index" */))
const _7c37bc65 = () => interopDefault(import('../pages/profile/settings/index.vue' /* webpackChunkName: "pages/profile/settings/index" */))
const _924658b6 = () => interopDefault(import('../pages/profile/statistics/index.vue' /* webpackChunkName: "pages/profile/statistics/index" */))
const _fea50b3a = () => interopDefault(import('../pages/profile/tariffs/index.vue' /* webpackChunkName: "pages/profile/tariffs/index" */))
const _55abc19e = () => interopDefault(import('../pages/signup/confirm-email.vue' /* webpackChunkName: "pages/signup/confirm-email" */))
const _44cd8736 = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _270200ba = () => interopDefault(import('../pages/bot-link/_link.vue' /* webpackChunkName: "pages/bot-link/_link" */))
const _7a50bdfc = () => interopDefault(import('../pages/confirm/_token.vue' /* webpackChunkName: "pages/confirm/_token" */))
const _08826f8a = () => interopDefault(import('../pages/countries/_.vue' /* webpackChunkName: "pages/countries/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/authorization",
    component: _4760c933,
    pathToRegexpOptions: {"strict":true},
    name: "authorization___en"
  }, {
    path: "/blog",
    component: _4684287e,
    pathToRegexpOptions: {"strict":true},
    name: "blog___en"
  }, {
    path: "/cn",
    components: {
      default: _9ab88bd4
    },
    name: "index___cn"
  }, {
    path: "/es",
    components: {
      default: _9ab88bd4
    },
    name: "index___es"
  }, {
    path: "/hi",
    components: {
      default: _9ab88bd4
    },
    name: "index___hi"
  }, {
    path: "/partner",
    component: _26067bfc,
    pathToRegexpOptions: {"strict":true},
    name: "partner___en"
  }, {
    path: "/profile",
    component: _7da889a3,
    pathToRegexpOptions: {"strict":true},
    name: "profile___en"
  }, {
    path: "/registration",
    component: _52824056,
    pathToRegexpOptions: {"strict":true},
    name: "registration___en"
  }, {
    path: "/request-password",
    component: _67b572b5,
    pathToRegexpOptions: {"strict":true},
    name: "request-password___en"
  }, {
    path: "/ru",
    components: {
      default: _9ab88bd4
    },
    name: "index___ru"
  }, {
    path: "/search",
    component: _72f5fa78,
    pathToRegexpOptions: {"strict":true},
    name: "search___en"
  }, {
    path: "/success-payment",
    component: _6a53b776,
    pathToRegexpOptions: {"strict":true},
    name: "success-payment___en"
  }, {
    path: "/tr",
    components: {
      default: _9ab88bd4
    },
    name: "index___tr"
  }, {
    path: "/cn/authorization",
    component: _4760c933,
    pathToRegexpOptions: {"strict":true},
    name: "authorization___cn"
  }, {
    path: "/cn/blog",
    component: _4684287e,
    pathToRegexpOptions: {"strict":true},
    name: "blog___cn"
  }, {
    path: "/cn/partner",
    component: _26067bfc,
    pathToRegexpOptions: {"strict":true},
    name: "partner___cn"
  }, {
    path: "/cn/profile",
    component: _7da889a3,
    pathToRegexpOptions: {"strict":true},
    name: "profile___cn"
  }, {
    path: "/cn/registration",
    component: _52824056,
    pathToRegexpOptions: {"strict":true},
    name: "registration___cn"
  }, {
    path: "/cn/request-password",
    component: _67b572b5,
    pathToRegexpOptions: {"strict":true},
    name: "request-password___cn"
  }, {
    path: "/cn/search",
    component: _72f5fa78,
    pathToRegexpOptions: {"strict":true},
    name: "search___cn"
  }, {
    path: "/cn/success-payment",
    component: _6a53b776,
    pathToRegexpOptions: {"strict":true},
    name: "success-payment___cn"
  }, {
    path: "/es/authorization",
    component: _4760c933,
    pathToRegexpOptions: {"strict":true},
    name: "authorization___es"
  }, {
    path: "/es/blog",
    component: _4684287e,
    pathToRegexpOptions: {"strict":true},
    name: "blog___es"
  }, {
    path: "/es/partner",
    component: _26067bfc,
    pathToRegexpOptions: {"strict":true},
    name: "partner___es"
  }, {
    path: "/es/profile",
    component: _7da889a3,
    pathToRegexpOptions: {"strict":true},
    name: "profile___es"
  }, {
    path: "/es/registration",
    component: _52824056,
    pathToRegexpOptions: {"strict":true},
    name: "registration___es"
  }, {
    path: "/es/request-password",
    component: _67b572b5,
    pathToRegexpOptions: {"strict":true},
    name: "request-password___es"
  }, {
    path: "/es/search",
    component: _72f5fa78,
    pathToRegexpOptions: {"strict":true},
    name: "search___es"
  }, {
    path: "/es/success-payment",
    component: _6a53b776,
    pathToRegexpOptions: {"strict":true},
    name: "success-payment___es"
  }, {
    path: "/hi/authorization",
    component: _4760c933,
    pathToRegexpOptions: {"strict":true},
    name: "authorization___hi"
  }, {
    path: "/hi/blog",
    component: _4684287e,
    pathToRegexpOptions: {"strict":true},
    name: "blog___hi"
  }, {
    path: "/hi/partner",
    component: _26067bfc,
    pathToRegexpOptions: {"strict":true},
    name: "partner___hi"
  }, {
    path: "/hi/profile",
    component: _7da889a3,
    pathToRegexpOptions: {"strict":true},
    name: "profile___hi"
  }, {
    path: "/hi/registration",
    component: _52824056,
    pathToRegexpOptions: {"strict":true},
    name: "registration___hi"
  }, {
    path: "/hi/request-password",
    component: _67b572b5,
    pathToRegexpOptions: {"strict":true},
    name: "request-password___hi"
  }, {
    path: "/hi/search",
    component: _72f5fa78,
    pathToRegexpOptions: {"strict":true},
    name: "search___hi"
  }, {
    path: "/hi/success-payment",
    component: _6a53b776,
    pathToRegexpOptions: {"strict":true},
    name: "success-payment___hi"
  }, {
    path: "/partner/activations-history",
    component: _973c0ad2,
    pathToRegexpOptions: {"strict":true},
    name: "partner-activations-history___en"
  }, {
    path: "/partner/price-regulator",
    component: _042c28f0,
    pathToRegexpOptions: {"strict":true},
    name: "partner-price-regulator___en"
  }, {
    path: "/partner/sim-card",
    component: _22950149,
    pathToRegexpOptions: {"strict":true},
    name: "partner-sim-card___en"
  }, {
    path: "/partner/statistic",
    component: _27eefe03,
    pathToRegexpOptions: {"strict":true},
    name: "partner-statistic___en"
  }, {
    path: "/partner/withdrawal-funds",
    component: _f935eb8e,
    pathToRegexpOptions: {"strict":true},
    name: "partner-withdrawal-funds___en"
  }, {
    path: "/profile/history",
    component: _0ff440e8,
    pathToRegexpOptions: {"strict":true},
    name: "profile-history___en"
  }, {
    path: "/profile/loyalty-program",
    component: _75aa77de,
    pathToRegexpOptions: {"strict":true},
    name: "profile-loyalty-program___en"
  }, {
    path: "/profile/pay",
    component: _43f7087c,
    pathToRegexpOptions: {"strict":true},
    name: "profile-pay___en"
  }, {
    path: "/profile/referral-program",
    component: _430c1f54,
    pathToRegexpOptions: {"strict":true},
    name: "profile-referral-program___en"
  }, {
    path: "/profile/sale-agreement",
    component: _429b59c6,
    pathToRegexpOptions: {"strict":true},
    name: "profile-sale-agreement___en"
  }, {
    path: "/profile/settings",
    component: _7c37bc65,
    pathToRegexpOptions: {"strict":true},
    name: "profile-settings___en"
  }, {
    path: "/profile/statistics",
    component: _924658b6,
    pathToRegexpOptions: {"strict":true},
    name: "profile-statistics___en"
  }, {
    path: "/profile/tariffs",
    component: _fea50b3a,
    pathToRegexpOptions: {"strict":true},
    name: "profile-tariffs___en"
  }, {
    path: "/ru/authorization",
    component: _4760c933,
    pathToRegexpOptions: {"strict":true},
    name: "authorization___ru"
  }, {
    path: "/ru/blog",
    component: _4684287e,
    pathToRegexpOptions: {"strict":true},
    name: "blog___ru"
  }, {
    path: "/ru/partner",
    component: _26067bfc,
    pathToRegexpOptions: {"strict":true},
    name: "partner___ru"
  }, {
    path: "/ru/profile",
    component: _7da889a3,
    pathToRegexpOptions: {"strict":true},
    name: "profile___ru"
  }, {
    path: "/ru/registration",
    component: _52824056,
    pathToRegexpOptions: {"strict":true},
    name: "registration___ru"
  }, {
    path: "/ru/request-password",
    component: _67b572b5,
    pathToRegexpOptions: {"strict":true},
    name: "request-password___ru"
  }, {
    path: "/ru/search",
    component: _72f5fa78,
    pathToRegexpOptions: {"strict":true},
    name: "search___ru"
  }, {
    path: "/ru/success-payment",
    component: _6a53b776,
    pathToRegexpOptions: {"strict":true},
    name: "success-payment___ru"
  }, {
    path: "/signup/confirm-email",
    component: _55abc19e,
    pathToRegexpOptions: {"strict":true},
    name: "signup-confirm-email___en"
  }, {
    path: "/tr/authorization",
    component: _4760c933,
    pathToRegexpOptions: {"strict":true},
    name: "authorization___tr"
  }, {
    path: "/tr/blog",
    component: _4684287e,
    pathToRegexpOptions: {"strict":true},
    name: "blog___tr"
  }, {
    path: "/tr/partner",
    component: _26067bfc,
    pathToRegexpOptions: {"strict":true},
    name: "partner___tr"
  }, {
    path: "/tr/profile",
    component: _7da889a3,
    pathToRegexpOptions: {"strict":true},
    name: "profile___tr"
  }, {
    path: "/tr/registration",
    component: _52824056,
    pathToRegexpOptions: {"strict":true},
    name: "registration___tr"
  }, {
    path: "/tr/request-password",
    component: _67b572b5,
    pathToRegexpOptions: {"strict":true},
    name: "request-password___tr"
  }, {
    path: "/tr/search",
    component: _72f5fa78,
    pathToRegexpOptions: {"strict":true},
    name: "search___tr"
  }, {
    path: "/tr/success-payment",
    component: _6a53b776,
    pathToRegexpOptions: {"strict":true},
    name: "success-payment___tr"
  }, {
    path: "/cn/partner/activations-history",
    component: _973c0ad2,
    pathToRegexpOptions: {"strict":true},
    name: "partner-activations-history___cn"
  }, {
    path: "/cn/partner/price-regulator",
    component: _042c28f0,
    pathToRegexpOptions: {"strict":true},
    name: "partner-price-regulator___cn"
  }, {
    path: "/cn/partner/sim-card",
    component: _22950149,
    pathToRegexpOptions: {"strict":true},
    name: "partner-sim-card___cn"
  }, {
    path: "/cn/partner/statistic",
    component: _27eefe03,
    pathToRegexpOptions: {"strict":true},
    name: "partner-statistic___cn"
  }, {
    path: "/cn/partner/withdrawal-funds",
    component: _f935eb8e,
    pathToRegexpOptions: {"strict":true},
    name: "partner-withdrawal-funds___cn"
  }, {
    path: "/cn/profile/history",
    component: _0ff440e8,
    pathToRegexpOptions: {"strict":true},
    name: "profile-history___cn"
  }, {
    path: "/cn/profile/loyalty-program",
    component: _75aa77de,
    pathToRegexpOptions: {"strict":true},
    name: "profile-loyalty-program___cn"
  }, {
    path: "/cn/profile/pay",
    component: _43f7087c,
    pathToRegexpOptions: {"strict":true},
    name: "profile-pay___cn"
  }, {
    path: "/cn/profile/referral-program",
    component: _430c1f54,
    pathToRegexpOptions: {"strict":true},
    name: "profile-referral-program___cn"
  }, {
    path: "/cn/profile/sale-agreement",
    component: _429b59c6,
    pathToRegexpOptions: {"strict":true},
    name: "profile-sale-agreement___cn"
  }, {
    path: "/cn/profile/settings",
    component: _7c37bc65,
    pathToRegexpOptions: {"strict":true},
    name: "profile-settings___cn"
  }, {
    path: "/cn/profile/statistics",
    component: _924658b6,
    pathToRegexpOptions: {"strict":true},
    name: "profile-statistics___cn"
  }, {
    path: "/cn/profile/tariffs",
    component: _fea50b3a,
    pathToRegexpOptions: {"strict":true},
    name: "profile-tariffs___cn"
  }, {
    path: "/cn/signup/confirm-email",
    component: _55abc19e,
    pathToRegexpOptions: {"strict":true},
    name: "signup-confirm-email___cn"
  }, {
    path: "/es/partner/activations-history",
    component: _973c0ad2,
    pathToRegexpOptions: {"strict":true},
    name: "partner-activations-history___es"
  }, {
    path: "/es/partner/price-regulator",
    component: _042c28f0,
    pathToRegexpOptions: {"strict":true},
    name: "partner-price-regulator___es"
  }, {
    path: "/es/partner/sim-card",
    component: _22950149,
    pathToRegexpOptions: {"strict":true},
    name: "partner-sim-card___es"
  }, {
    path: "/es/partner/statistic",
    component: _27eefe03,
    pathToRegexpOptions: {"strict":true},
    name: "partner-statistic___es"
  }, {
    path: "/es/partner/withdrawal-funds",
    component: _f935eb8e,
    pathToRegexpOptions: {"strict":true},
    name: "partner-withdrawal-funds___es"
  }, {
    path: "/es/profile/history",
    component: _0ff440e8,
    pathToRegexpOptions: {"strict":true},
    name: "profile-history___es"
  }, {
    path: "/es/profile/loyalty-program",
    component: _75aa77de,
    pathToRegexpOptions: {"strict":true},
    name: "profile-loyalty-program___es"
  }, {
    path: "/es/profile/pay",
    component: _43f7087c,
    pathToRegexpOptions: {"strict":true},
    name: "profile-pay___es"
  }, {
    path: "/es/profile/referral-program",
    component: _430c1f54,
    pathToRegexpOptions: {"strict":true},
    name: "profile-referral-program___es"
  }, {
    path: "/es/profile/sale-agreement",
    component: _429b59c6,
    pathToRegexpOptions: {"strict":true},
    name: "profile-sale-agreement___es"
  }, {
    path: "/es/profile/settings",
    component: _7c37bc65,
    pathToRegexpOptions: {"strict":true},
    name: "profile-settings___es"
  }, {
    path: "/es/profile/statistics",
    component: _924658b6,
    pathToRegexpOptions: {"strict":true},
    name: "profile-statistics___es"
  }, {
    path: "/es/profile/tariffs",
    component: _fea50b3a,
    pathToRegexpOptions: {"strict":true},
    name: "profile-tariffs___es"
  }, {
    path: "/es/signup/confirm-email",
    component: _55abc19e,
    pathToRegexpOptions: {"strict":true},
    name: "signup-confirm-email___es"
  }, {
    path: "/hi/partner/activations-history",
    component: _973c0ad2,
    pathToRegexpOptions: {"strict":true},
    name: "partner-activations-history___hi"
  }, {
    path: "/hi/partner/price-regulator",
    component: _042c28f0,
    pathToRegexpOptions: {"strict":true},
    name: "partner-price-regulator___hi"
  }, {
    path: "/hi/partner/sim-card",
    component: _22950149,
    pathToRegexpOptions: {"strict":true},
    name: "partner-sim-card___hi"
  }, {
    path: "/hi/partner/statistic",
    component: _27eefe03,
    pathToRegexpOptions: {"strict":true},
    name: "partner-statistic___hi"
  }, {
    path: "/hi/partner/withdrawal-funds",
    component: _f935eb8e,
    pathToRegexpOptions: {"strict":true},
    name: "partner-withdrawal-funds___hi"
  }, {
    path: "/hi/profile/history",
    component: _0ff440e8,
    pathToRegexpOptions: {"strict":true},
    name: "profile-history___hi"
  }, {
    path: "/hi/profile/loyalty-program",
    component: _75aa77de,
    pathToRegexpOptions: {"strict":true},
    name: "profile-loyalty-program___hi"
  }, {
    path: "/hi/profile/pay",
    component: _43f7087c,
    pathToRegexpOptions: {"strict":true},
    name: "profile-pay___hi"
  }, {
    path: "/hi/profile/referral-program",
    component: _430c1f54,
    pathToRegexpOptions: {"strict":true},
    name: "profile-referral-program___hi"
  }, {
    path: "/hi/profile/sale-agreement",
    component: _429b59c6,
    pathToRegexpOptions: {"strict":true},
    name: "profile-sale-agreement___hi"
  }, {
    path: "/hi/profile/settings",
    component: _7c37bc65,
    pathToRegexpOptions: {"strict":true},
    name: "profile-settings___hi"
  }, {
    path: "/hi/profile/statistics",
    component: _924658b6,
    pathToRegexpOptions: {"strict":true},
    name: "profile-statistics___hi"
  }, {
    path: "/hi/profile/tariffs",
    component: _fea50b3a,
    pathToRegexpOptions: {"strict":true},
    name: "profile-tariffs___hi"
  }, {
    path: "/hi/signup/confirm-email",
    component: _55abc19e,
    pathToRegexpOptions: {"strict":true},
    name: "signup-confirm-email___hi"
  }, {
    path: "/ru/partner/activations-history",
    component: _973c0ad2,
    pathToRegexpOptions: {"strict":true},
    name: "partner-activations-history___ru"
  }, {
    path: "/ru/partner/price-regulator",
    component: _042c28f0,
    pathToRegexpOptions: {"strict":true},
    name: "partner-price-regulator___ru"
  }, {
    path: "/ru/partner/sim-card",
    component: _22950149,
    pathToRegexpOptions: {"strict":true},
    name: "partner-sim-card___ru"
  }, {
    path: "/ru/partner/statistic",
    component: _27eefe03,
    pathToRegexpOptions: {"strict":true},
    name: "partner-statistic___ru"
  }, {
    path: "/ru/partner/withdrawal-funds",
    component: _f935eb8e,
    pathToRegexpOptions: {"strict":true},
    name: "partner-withdrawal-funds___ru"
  }, {
    path: "/ru/profile/history",
    component: _0ff440e8,
    pathToRegexpOptions: {"strict":true},
    name: "profile-history___ru"
  }, {
    path: "/ru/profile/loyalty-program",
    component: _75aa77de,
    pathToRegexpOptions: {"strict":true},
    name: "profile-loyalty-program___ru"
  }, {
    path: "/ru/profile/pay",
    component: _43f7087c,
    pathToRegexpOptions: {"strict":true},
    name: "profile-pay___ru"
  }, {
    path: "/ru/profile/referral-program",
    component: _430c1f54,
    pathToRegexpOptions: {"strict":true},
    name: "profile-referral-program___ru"
  }, {
    path: "/ru/profile/sale-agreement",
    component: _429b59c6,
    pathToRegexpOptions: {"strict":true},
    name: "profile-sale-agreement___ru"
  }, {
    path: "/ru/profile/settings",
    component: _7c37bc65,
    pathToRegexpOptions: {"strict":true},
    name: "profile-settings___ru"
  }, {
    path: "/ru/profile/statistics",
    component: _924658b6,
    pathToRegexpOptions: {"strict":true},
    name: "profile-statistics___ru"
  }, {
    path: "/ru/profile/tariffs",
    component: _fea50b3a,
    pathToRegexpOptions: {"strict":true},
    name: "profile-tariffs___ru"
  }, {
    path: "/ru/signup/confirm-email",
    component: _55abc19e,
    pathToRegexpOptions: {"strict":true},
    name: "signup-confirm-email___ru"
  }, {
    path: "/tr/partner/activations-history",
    component: _973c0ad2,
    pathToRegexpOptions: {"strict":true},
    name: "partner-activations-history___tr"
  }, {
    path: "/tr/partner/price-regulator",
    component: _042c28f0,
    pathToRegexpOptions: {"strict":true},
    name: "partner-price-regulator___tr"
  }, {
    path: "/tr/partner/sim-card",
    component: _22950149,
    pathToRegexpOptions: {"strict":true},
    name: "partner-sim-card___tr"
  }, {
    path: "/tr/partner/statistic",
    component: _27eefe03,
    pathToRegexpOptions: {"strict":true},
    name: "partner-statistic___tr"
  }, {
    path: "/tr/partner/withdrawal-funds",
    component: _f935eb8e,
    pathToRegexpOptions: {"strict":true},
    name: "partner-withdrawal-funds___tr"
  }, {
    path: "/tr/profile/history",
    component: _0ff440e8,
    pathToRegexpOptions: {"strict":true},
    name: "profile-history___tr"
  }, {
    path: "/tr/profile/loyalty-program",
    component: _75aa77de,
    pathToRegexpOptions: {"strict":true},
    name: "profile-loyalty-program___tr"
  }, {
    path: "/tr/profile/pay",
    component: _43f7087c,
    pathToRegexpOptions: {"strict":true},
    name: "profile-pay___tr"
  }, {
    path: "/tr/profile/referral-program",
    component: _430c1f54,
    pathToRegexpOptions: {"strict":true},
    name: "profile-referral-program___tr"
  }, {
    path: "/tr/profile/sale-agreement",
    component: _429b59c6,
    pathToRegexpOptions: {"strict":true},
    name: "profile-sale-agreement___tr"
  }, {
    path: "/tr/profile/settings",
    component: _7c37bc65,
    pathToRegexpOptions: {"strict":true},
    name: "profile-settings___tr"
  }, {
    path: "/tr/profile/statistics",
    component: _924658b6,
    pathToRegexpOptions: {"strict":true},
    name: "profile-statistics___tr"
  }, {
    path: "/tr/profile/tariffs",
    component: _fea50b3a,
    pathToRegexpOptions: {"strict":true},
    name: "profile-tariffs___tr"
  }, {
    path: "/tr/signup/confirm-email",
    component: _55abc19e,
    pathToRegexpOptions: {"strict":true},
    name: "signup-confirm-email___tr"
  }, {
    path: "/cn/blog/:slug",
    component: _44cd8736,
    pathToRegexpOptions: {"strict":true},
    name: "blog-slug___cn"
  }, {
    path: "/cn/bot-link/:link?",
    component: _270200ba,
    pathToRegexpOptions: {"strict":true},
    name: "bot-link-link___cn"
  }, {
    path: "/cn/confirm/:token?",
    component: _7a50bdfc,
    pathToRegexpOptions: {"strict":true},
    name: "confirm-token___cn"
  }, {
    path: "/es/blog/:slug",
    component: _44cd8736,
    pathToRegexpOptions: {"strict":true},
    name: "blog-slug___es"
  }, {
    path: "/es/bot-link/:link?",
    component: _270200ba,
    pathToRegexpOptions: {"strict":true},
    name: "bot-link-link___es"
  }, {
    path: "/es/confirm/:token?",
    component: _7a50bdfc,
    pathToRegexpOptions: {"strict":true},
    name: "confirm-token___es"
  }, {
    path: "/hi/blog/:slug",
    component: _44cd8736,
    pathToRegexpOptions: {"strict":true},
    name: "blog-slug___hi"
  }, {
    path: "/hi/bot-link/:link?",
    component: _270200ba,
    pathToRegexpOptions: {"strict":true},
    name: "bot-link-link___hi"
  }, {
    path: "/hi/confirm/:token?",
    component: _7a50bdfc,
    pathToRegexpOptions: {"strict":true},
    name: "confirm-token___hi"
  }, {
    path: "/ru/blog/:slug",
    component: _44cd8736,
    pathToRegexpOptions: {"strict":true},
    name: "blog-slug___ru"
  }, {
    path: "/ru/bot-link/:link?",
    component: _270200ba,
    pathToRegexpOptions: {"strict":true},
    name: "bot-link-link___ru"
  }, {
    path: "/ru/confirm/:token?",
    component: _7a50bdfc,
    pathToRegexpOptions: {"strict":true},
    name: "confirm-token___ru"
  }, {
    path: "/tr/blog/:slug",
    component: _44cd8736,
    pathToRegexpOptions: {"strict":true},
    name: "blog-slug___tr"
  }, {
    path: "/tr/bot-link/:link?",
    component: _270200ba,
    pathToRegexpOptions: {"strict":true},
    name: "bot-link-link___tr"
  }, {
    path: "/tr/confirm/:token?",
    component: _7a50bdfc,
    pathToRegexpOptions: {"strict":true},
    name: "confirm-token___tr"
  }, {
    path: "/hi/countries/*",
    component: _08826f8a,
    pathToRegexpOptions: {"strict":true},
    name: "countries-all___hi"
  }, {
    path: "/es/countries/*",
    component: _08826f8a,
    pathToRegexpOptions: {"strict":true},
    name: "countries-all___es"
  }, {
    path: "/tr/countries/*",
    component: _08826f8a,
    pathToRegexpOptions: {"strict":true},
    name: "countries-all___tr"
  }, {
    path: "/cn/countries/*",
    component: _08826f8a,
    pathToRegexpOptions: {"strict":true},
    name: "countries-all___cn"
  }, {
    path: "/ru/countries/*",
    component: _08826f8a,
    pathToRegexpOptions: {"strict":true},
    name: "countries-all___ru"
  }, {
    path: "/blog/:slug",
    component: _44cd8736,
    pathToRegexpOptions: {"strict":true},
    name: "blog-slug___en"
  }, {
    path: "/bot-link/:link?",
    component: _270200ba,
    pathToRegexpOptions: {"strict":true},
    name: "bot-link-link___en"
  }, {
    path: "/confirm/:token?",
    component: _7a50bdfc,
    pathToRegexpOptions: {"strict":true},
    name: "confirm-token___en"
  }, {
    path: "/hi/*",
    component: _9ab88bd4,
    pathToRegexpOptions: {"strict":true},
    name: "all___hi"
  }, {
    path: "/es/*",
    component: _9ab88bd4,
    pathToRegexpOptions: {"strict":true},
    name: "all___es"
  }, {
    path: "/tr/*",
    component: _9ab88bd4,
    pathToRegexpOptions: {"strict":true},
    name: "all___tr"
  }, {
    path: "/cn/*",
    component: _9ab88bd4,
    pathToRegexpOptions: {"strict":true},
    name: "all___cn"
  }, {
    path: "/ru/*",
    component: _9ab88bd4,
    pathToRegexpOptions: {"strict":true},
    name: "all___ru"
  }, {
    path: "/countries/*",
    component: _08826f8a,
    pathToRegexpOptions: {"strict":true},
    name: "countries-all___en"
  }, {
    path: "/*",
    component: _9ab88bd4,
    pathToRegexpOptions: {"strict":true},
    name: "all___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
